.modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 20;
    display: none;
    justify-content: center;
    align-items: center;

    &-wrapper {
        max-width: 400px;
        background-color: $color-bg;
        padding: 2rem;
        border-radius: 0.5rem;
        box-shadow: $box-shadow-7;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-close {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        
        &__icon {
            @include ff-icon;
            color: $color-grey-2;
            font-weight: lighter;
            cursor: pointer;
        }
    }

    &-header {
        font-size: 1.4rem;
        font-weight: bold;
        padding-bottom: 1.5rem;
    }

    &-text {
        width: 76%;
        font-size: 1.3rem;
        text-align: center;
        padding-bottom: 1.5rem;
        color: $color-grey-1;
    }

    &-buttons {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        padding-bottom: 1.5rem;
    }

    &-input {
        width: 65%;
        margin-bottom: 1rem;
        border: 1px solid $color-primary;
        border-radius: 2rem;
        padding: 1rem 2.5rem;
        outline: none;

        &::placeholder {
            color: $color-primary;
        }
    }

    &-footer {
        width: 65%;
        display: flex;
        align-items: flex-start;
        text-align: center;
        font-size: 1rem;
        color: $color-grey-1;
    }

    &-checkbox {
        margin: 0;
        margin-top: 0.15rem;
        border: 1px solid $color-grey-1;
        
    }

    &.is-open {
        display: none;
    }

}