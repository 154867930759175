.regulations {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 2rem;
    padding-top: 12rem;
    padding-bottom: 12rem;


    &__center {
        max-width: $dim-breakpoint-lg;
        display: flex;
        flex-direction: column;
    }

    &__header {
        font-size: 3rem;
        padding-bottom: 2rem;
    }

    &__text {
        font-size: 1.5rem;
        line-height: 2rem;
    }

    &__bullets {
        font-size: 1.5rem;

        & li {
            padding: 1rem;
        }
    }
}