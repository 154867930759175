.pricing {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 8rem;
  align-items: center;
  justify-content: center;
  background-color: $color-grey-4;

  @media (min-width: $dim-breakpoint-md-lg) {
    padding-top: 16rem;
  }

  &-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: $dim-breakpoint-lg;
    width: 100%;
    padding: 0 1rem;
    padding-bottom: 4rem;
    @media (min-width: $dim-breakpoint-mdbg) {
      padding: 0;
      padding-bottom: 4rem;
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (min-width: $dim-breakpoint-md-lg) {
      display: flex;
      max-width: 3/4 * 100%;
      width: 100%;
      padding: 2rem;
      border: 1px solid $color-grey-3;
      background-color: $color-bg;
    }

    &-footer {
      color: $color-grey-1;
      font-size: 1.4rem;

      &.bottom {
        padding-bottom: 6rem;
      }
    }

    &__header {
      padding: 2rem 0;
      font-size: 4.4rem;
      font-weight: lighter;

      &.primary {
        color: $color-primary;
      }
    }

    &__subheader {
      padding: 2rem 0 1rem;
      font-size: 2.8rem;
      font-weight: lighter;
      text-align: center;
    }

    &__text {
      color: $color-bg-black;
      font-size: 1.6rem;
      text-align: center;
      font-weight: lighter;
      padding: 1rem 2rem 1rem;
      padding-bottom: 3rem;

      &.nopadding {
        padding: 0;
        padding-bottom: 3rem;
      }
    }

    &-columns {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (min-width: $dim-breakpoint-md) {
        flex-direction: row;
        justify-content: center;
      }
    }

    &-column {
      display: flex;
      flex-direction: column;
      flex: 0 0 40%;
      text-align: center;
      padding-bottom: 2rem;

      &-title {
        color: $color-primary;
        font-size: 5.2rem;
        font-weight: lighter;
        padding: 1rem 0;
      }
    }

    &__photo {
      width: 100%;
      padding-bottom: 6rem;
      position: relative;

      &-img {
        width: 100%;
      }

      &-arrow {
        position: absolute;
        display: flex;
        flex-direction: column;
        bottom: 20%;
        right: 5%;
        width: 20%;

        &-img {
          width: 50%;

          @media (min-width: $dim-breakpoint-md) {
            width: 60%;
          }
        }
        &-text {
          padding-top: 1rem;
          font-size: 1.2rem;
          text-align: center;
          font-weight: lighter;

          @media (min-width: $dim-breakpoint-md) {
            font-size: 1.6rem;
          }
        }
      }
    }

    &-row {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding-bottom: 2rem;

      @media (min-width: $dim-breakpoint-md) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 1rem;
      }

      &-left {
        display: flex;
        color: $color-bg-black;
        font-size: 2.4rem;
        font-weight: lighter;
        padding-bottom: 1rem;

        @media (min-width: $dim-breakpoint-md) {
          display: flex;
          flex: 0 0 50%;
          justify-content: center;
        }
      }

      &-right {
        display: flex;
        align-items: center;
        padding-bottom: 1.5rem;
        font-size: 4rem;
        color: $color-primary;
        font-weight: lighter;

        @media (min-width: $dim-breakpoint-md) {
          display: flex;
          flex: 0 0 50%;
          justify-content: center;
        }

        &.small {
          font-size: 2rem;
          font-weight: lighter;
        }
      }

      &__icon {
        @include ff-icon;
        padding-left: 0.5rem;
      }
    }

    &__buttons {
      padding-top: 5rem;

      &.big {
        font-size: 2rem;
      }
    }

    &-black {
      display: flex;
      justify-content: center;
      max-width: 1100px;
      width: 100%;
      background-color: $color-bg-black;
      border: none;
      color: $color-grey-2;

      &-columns {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 8rem 0 3rem;

        @media (min-width: $dim-breakpoint-mdbg) {
          flex-direction: row;
          align-items: flex-end;
          padding: 8rem 1rem 3rem;
        }
      }

      &-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0 1rem;

        &.bottom {
          @media (min-width: $dim-breakpoint-mdbg) {
            padding-bottom: 10rem;
          }
        }

        &-content {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 2rem;
          margin-bottom: 2rem;
          box-shadow: $box-shadow-9;
        }

        @media (min-width: $dim-breakpoint-mdbg) {
          flex: 0 0 50%;
        }
      }

      &__header {
        display: flex;
        width: 100%;
        font-size: 3rem;
        font-weight: lighter;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 2rem 0;

        &.primary {
          color: $color-primary;
          font-size: 4rem;
        }
      }

      &__text {
        display: flex;
        justify-content: center;
        width: 100%;
        font-size: 1.6rem;
        font-weight: lighter;
        text-align: center;
      }

      &__photo {
        width: 100%;
        padding: 1rem 0;

        &-img {
          border-radius: 0.5rem;
          width: 100%;
        }
      }
    }
  }

  &-presentation {
    display: flex;
    width: 100%;
    position: relative;
    background-color: $color-bg-black;

    &-monitor {
      display: flex;
      top: 20%;
      left: 6.7%;
      width: 87%;
      height: 50%;
      background-color: black;
      position: absolute;
      @media (min-width: $dim-breakpoint-md) {
        top: 7.2%;
        left: 9.1%;
        width: 82.2%;
        height: 59.1%;
      }
      @media (min-width: $dim-breakpoint-lg) {
        top: 12.1%;
        left: 16.3%;
        width: 67.75%;
        height: 55%;
      }

      @media (min-width: 1600px) {
        top: 12.1%;
        left: 21%;
        width: 58.3%;
        height: 55.7%;
      }
      @media (min-width: 2100px) {
        top: 12.05%;
        left: 25.9%;
        width: 48.5%;
        height: 54.9%;
      }
      &-iframe {
        width: 100%;
        height: 100%;
      }

      &-wrapper {
        height: 100%;
        width: 100%;
        position: relative;
      }
    }

    &-image {
      width: 100%;
      height: 100vw;
      background-image: url("../../assets/mac-cennik.jpg");
      background-position: center 90%;
      background-repeat: no-repeat;
      background-size: 180% auto;

      @media (min-width: $dim-breakpoint-md) {
        height: 80vw;
        background-size: 170% auto;
      }

      @media (min-width: $dim-breakpoint-lg) {
        height: 70vw;
        background-size: 140% auto;
      }

      @media (min-width: 1600px) {
        height: 60vw;
        background-size: 120% auto;
      }

      @media (min-width: 2100px) {
        height: 50vw;
        background-size: 100%;
      }

      // @media (min-width: $dim-breakpoint-md-lg) {
      //     height: 660px;
      // }
    }
  }

  &.black {
    background-color: $color-bg-black;
    padding: 2rem 0;
  }

  &.dollhouse {
    @media (min-width: $dim-breakpoint-md-lg) {
      background-image: url(../../assets/pricingBackground.jpg);
      background-repeat: no-repeat;
      background-position: center center;
      // background-size: 200% auto;
      background-size: cover;
      padding-bottom: 6rem;
    }

    // @media (min-width: $dim-breakpoint-lg) {
    //     background-size: 180% auto;
    //     background-position: 85% center;
    // }
    // @media (min-width: 1600px) {
    //     background-size: 130% auto;
    //     background-position: 110% center;
    // }
    // @media (min-width: 2100px) {
    //     background-size: 100% auto;
    //     background-position: center;
    // }
  }
}
