.about {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;
  justify-content: center;
  align-items: center;
  position: relative;

  &__360container {
    position: relative;
    display: flex;
    height: 450px;
    max-width: 800px;
    width: 100%;
    border: none;
    padding-bottom: 4rem;

    @media(min-width: $dim-breakpoint-smlr) {
        height: 550px;
    }

    @media(min-width: $dim-breakpoint-sm) {
        height: 600px;
    }

    @media(min-width: $dim-breakpoint-md) {
        height: 700px;
    }
  }

  &-bg {
    width: 100%;
    position: relative;
    padding: 4rem 2rem;
    background-color: $color-bg-black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-top {
      width: 100%;
      height: 4vw;
      position: absolute;
      overflow: hidden;
      top: -3vw;
      @media (min-width: $dim-breakpoint-md-lg) {
        height: 2vw;
        top: -1.5vw;
      }
    }

    &-bottom {
      width: 100%;
      height: 4vw;
      bottom: 2vw;
      overflow: hidden;
      position: relative;
      @media (min-width: $dim-breakpoint-md-lg) {
        height: 2vw;
        bottom: 0.5vw;
      }
    }
  }

  // &.black {
  //     &::before {
  //         content: "";
  //         width: 150%;
  //         height: 20%;
  //         left: -25%;
  //         transform: rotate(-1deg);
  //         position: absolute;
  //         background-color: $color-bg-black;
  //         transform-origin: center top;
  //         top: -10%;
  //         z-index: 1;
  //     }

  //     &::after {
  //         content: "";
  //         width: 150%;
  //         height: 20%;
  //         left: -25%;
  //         transform: rotate(1deg);
  //         position: absolute;
  //         background-color: $color-bg-black;
  //         transform-origin: center bottom;
  //         bottom: -10%;
  //         z-index: 1;
  //     }
  // }

  &-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: $dim-breakpoint-lg;
    width: 100%;
    z-index: 2;
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 2.6rem;
    padding: 0 2rem;
    padding-bottom: 2rem;

    @media (min-width: $dim-breakpoint-md) {
      font-size: 4rem;
    }

    @media (min-width: $dim-breakpoint-md-lg) {
      font-size: 5.2rem;
    }
  }

  &__subheader {
    max-width: 750px;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    color: black;
    text-align: center;
    padding: 0 2rem 3rem;
    font-weight: 300;

    @media (min-width: $dim-breakpoint-md) {
      font-size: 1.9rem;
    }

    @media (min-width: $dim-breakpoint-md-lg) {
      font-size: 2.2rem;
    }
  }

  &__container {
    width: 100%;
    padding-left: 3rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;

    max-width: 1000px;

    @media (min-width: $dim-breakpoint-md) {
      padding-left: 0;
      padding-top: 6rem;
    }

    &-infos {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      // border-left: 1px solid white;

      @media (min-width: $dim-breakpoint-md) {
        display: flex;
        flex-direction: row;
      }

      &__content {
        display: flex;
        padding: 0 0 4rem 0;
        flex: 0 0 1/3 * 100%;
        align-items: center;
        font-size: 1.5rem;
        position: relative;

        @media (min-width: $dim-breakpoint-md) {
          display: flex;
          flex-direction: column;
          padding: 3rem 0 0;
        }

        &::before {
          content: "";
          width: 1px;
          height: 100%;
          background-color: $color-primary;
          position: absolute;
          top: 1rem;
          left: 0;

          @media (min-width: $dim-breakpoint-md) {
            width: 100%;
            height: 1px;
            top: 0;
            left: 1/2 * 100%;
          }
        }

        &:last-child {
          padding-bottom: 0;

          &::before {
            display: none;
          }
        }

        &-absolute {
          position: absolute;
          display: flex;
          justify-content: space-between;
          align-items: center;
          left: -3rem;
          width: 4rem;
          top: 0;

          @media (min-width: $dim-breakpoint-md) {
            left: 0;
            display: flex;
            flex-direction: column;
            width: 1 * 100%;
            top: -6rem;
            align-items: center;
            height: 7rem;
            justify-content: space-between;
          }

          &-circle {
            width: 1rem;
            height: 1rem;
            background-color: $color-primary;
            border-radius: 50%;
            margin: 0 0.5rem;

            @media (min-width: $dim-breakpoint-md) {
              width: 1.8rem;
              height: 1.8rem;
              margin: 0;
            }

            &.big {
              @include ff-icon;
              width: 2rem;
              height: 2rem;
              margin: 0;
              font-size: 1.5rem;

              display: flex;
              justify-content: center;
              align-items: center;

              @media (min-width: $dim-breakpoint-md) {
                width: 4rem;
                height: 4rem;
                margin-bottom: -1rem;
                font-size: 3rem;
              }
            }
          }

          &-number {
            font-size: 2rem;
            color: $color-primary;
            font-weight: 300;

            @media (min-width: $dim-breakpoint-md) {
              padding-right: 0;
              font-size: 3rem;
            }
          }
        }

        &-text {
          padding-left: 2rem;
          padding-top: 0.15rem;

          @media (min-width: $dim-breakpoint-md) {
            padding: 2rem 1rem 0;
            text-align: center;
            font-size: 2.2rem;
            font-weight: 400;
          }

          @media (min-width: $dim-breakpoint-mdbg) {
            width: 70%;
          }

          @media (min-width: $dim-breakpoint-md-lg) {
            font-size: 1.8rem;
            width: 67%;
          }
        }
      }
    }

    &-button {
      padding-top: 1rem;
      display: flex;
      justify-content: center;
      padding-bottom: 2rem;

      @media (min-width: $dim-breakpoint-md) {
        padding: 8rem 0 4rem;
      }
    }
  }

  &.white {
    background-color: $color-bg;
    color: black;
    padding-top: 3rem;
    padding-bottom: 4rem;
  }

  &__list {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;

    &-wrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      @media (min-width: $dim-breakpoint-md-lg) {
        width: 4/5 * 100%;
      }
    }

    &-cell {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: stretch;
      flex: 0 0 2/3 * 100%;
      padding: 3rem 1rem;

      @media (min-width: $dim-breakpoint-smlr) {
        flex: 0 0 1/2 * 100%;
      }

      @media (min-width: $dim-breakpoint-md) {
        flex: 0 0 1/3 * 100%;
      }

      &-photo {
        width: 6rem;
        height: 8rem;
        padding-bottom: 2rem;

        &__image {
          width: 100%;
          height: 100%;
          color: $color-primary;
        }
      }

      &-header {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        font-weight: bold;
        padding-top: 1rem;
        text-align: center;
        padding-bottom: 1rem;

        @media (min-width: $dim-breakpoint-md) {
          font-size: 1.7rem;
        }

        @media (min-width: $dim-breakpoint-md-lg) {
          max-width: 70%;
          font-size: 2rem;
        }
      }

      &-description {
        display: flex;
        font-size: 1.2rem;
        text-align: center;

        @media (min-width: $dim-breakpoint-md) {
          font-size: 1.4rem;
        }

        @media (min-width: $dim-breakpoint-md-lg) {
          max-width: 80%;
          font-size: 1.6rem;
        }
      }
    }
  }
}
