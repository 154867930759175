.styles {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: black;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (min-width: $dim-breakpoint-lg) {
    padding-bottom: 5rem;

    &.black {
      padding-bottom: 0;
    }
  }

  @media (min-width: 1600px) {
    padding-bottom: 7rem;

    &.black {
      padding-bottom: 0;
    }
  }

  &-bg {
    width: 100%;
    position: relative;
    padding: 2rem;
    background-color: $color-bg-black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-top {
      width: 100%;
      height: 4vw;
      position: absolute;
      overflow: hidden;
      top: -3vw;

      @media (min-width: $dim-breakpoint-md-lg) {
        height: 2vw;
        top: -1.5vw;
      }
    }

    &-bottom {
      width: 100%;
      height: 4vw;
      bottom: 1vw;
      overflow: hidden;
      position: relative;
      @media (min-width: $dim-breakpoint-md-lg) {
        height: 2vw;
        bottom: 0.5vw;
      }
    }
  }

  &-center {
    display: flex;
    flex-direction: column;
    max-width: $dim-breakpoint-lg;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 4rem;

    &.no-margin {
      margin: 0;
    }
  }

  &__header {
    display: flex;
    justify-content: center;
    max-width: $dim-breakpoint-lg;
    width: 100%;
    font-size: 4rem;
    text-align: center;
    padding: 0 2rem 1rem;
    padding-bottom: 1rem;
    font-weight: 400;

    @media (min-width: $dim-breakpoint-lgsm) {
      font-size: 5.2rem;
    }

    &.big {
      font-size: 4rem;
      padding-bottom: 6rem;
      font-weight: 400;

      @media (min-width: $dim-breakpoint-md) {
        padding-bottom: 2rem;
      }

      @media (min-width: $dim-breakpoint-md-lg) {
        font-size: 6rem;
        padding-bottom: 4rem;
      }
    }
  }

  &__subheader {
    display: flex;
    justify-content: center;
    text-align: center;
    max-width: $dim-breakpoint-lg;
    width: 100%;
    font-size: 1.9rem;
    color: $color-bg-black;
    padding: 0 2rem 3rem;
    font-weight: 300;

    @media (min-width: $dim-breakpoint-lgsm) {
      font-size: 2.6rem;
    }
  }

  &__list {
    display: flex;
    justify-content: center;
    padding: 2rem 0;
    width: 100%;
    flex-wrap: wrap;

    @media (min-width: $dim-breakpoint-md) {
      padding: 2rem 1rem;
    }

    @media (min-width: $dim-breakpoint-md-lg) {
      width: 7.75/10 * 100%;
    }

    &-cell {
      display: flex;
      flex: 0 0 1 * 90%;
      padding: 1rem 0rem;

      @media (min-width: $dim-breakpoint-smlr) {
        flex: 0 0 9/10 * 100%;
      }

      @media (min-width: $dim-breakpoint-md) {
        flex: 0 0 1/2 * 100%;
        padding: 1rem;
      }

      &-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        transition: 0.4s;
        border: 1px solid $color-grey-6;
        border-radius: 0.6rem;

        &:hover {
          box-shadow: $box-shadow-1;
        }
      }

      &-photo {
        width: 100%;

        &__image {
          width: 100%;
          border-radius: 0.6rem 0.6rem 0 0;
          height: 152px;
          object-fit: cover;

          @media (min-width: $dim-breakpoint-sm) {
            height: 192px;
          }
        }
      }

      &-header {
        display: flex;
        justify-content: center;
        padding: 2rem 1rem 1rem;
        font-size: 1.6rem;
        font-weight: bold;

        @media (min-width: $dim-breakpoint-md-lg) {
          font-size: 1.8rem;
        }
      }

      &-description {
        display: flex;
        justify-content: center;
        text-align: center;
        padding-bottom: 1rem;
        font-size: 1.4rem;
        @media (min-width: $dim-breakpoint-md-lg) {
          font-size: 1.4rem;
        }
      }
    }

    &-buttons {
      display: flex;
      justify-content: center;
      padding-bottom: 2rem;

      @media (min-width: $dim-breakpoint-md) {
        padding-top: 2rem;
        padding-bottom: 2rem;
      }

      @media (min-width: $dim-breakpoint-md-lg) {
        font-size: 2rem;
      }

      &.no-padding {
        padding: 0;
      }
    }
  }

  &.black {
    color: $color-bg;
  }

  &__offers {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-end;
    padding-bottom: 4rem;

    @media (min-width: $dim-breakpoint-md) {
      padding-top: 0rem;
    }

    &-offer {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 0 0 90%;
      padding: 1rem 0 3rem;

      @media (min-width: $dim-breakpoint-md) {
        flex: 0 0 1/3 * 100%;
        padding: 1rem 0 2rem;
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 4.2rem;
        text-align: center;
        font-weight: 400;

        @media (min-width: $dim-breakpoint-md) {
          font-size: 4rem;
          height: 120px;
        }

        @media (min-width: 690px) {
          height: 80px;
        }

        @media (min-width: $dim-breakpoint-md-lg) {
          font-size: 5.2rem;
          height: 90px;
        }

        &.small {
          font-size: 3.8rem;
          text-decoration: none;
          color: white;

          @media (min-width: $dim-breakpoint-md) {
            font-size: 2.5rem;
          }

          @media (min-width: 910px) {
            font-size: 3.8rem;
          }
        }
      }

      &__footer {
        font-size: 1.4rem;
        font-weight: 300;
        padding-bottom: 2rem;
        color: $color-grey-7;

        @media (min-width: $dim-breakpoint-md) {
          font-size: 1rem;
          text-align: center;
        }

        @media (min-width: $dim-breakpoint-md-lg) {
          font-size: 1.4rem;
          text-align: center;
        }
      }

      &__description {
        font-size: 2.6rem;
        font-weight: 400;
        height: 64px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-grey-7;

        @media (min-width: $dim-breakpoint-md) {
          font-size: 2rem;
          text-align: center;
        }

        @media (min-width: $dim-breakpoint-md-lg) {
          font-size: 2.6rem;
          text-align: center;
        }
      }
    }
  }
  &-presentation {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;

    &-bg {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
    }

    &-button {
      position: absolute;
      top: 1rem;
      right: 1rem;
      z-index: 11;

      @media (min-width: $dim-breakpoint-md) {
        top: 6%;
        right: 6%;
      }
    }

    &-iframe {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 1;

      @media (min-width: $dim-breakpoint-md) {
        width: 90%;
        height: 90%;
        border-radius: 2rem;
      }
    }
  }
}
