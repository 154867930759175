.reading {
    width: 100%;
    display: flex;
    padding-top: 8rem;
    align-items: center;
    justify-content: center;
    background-color: $color-bg;

    &__goback {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        padding: 3rem 2rem;
        font-size: 1.6rem;
        color: $color-grey-1;
        text-decoration: none;
        align-items: center;

        &-i {
            @include ff-icon;
            font-size: 2rem;
            text-decoration: none;
            padding-top: 0.15rem;
        }

        &-wrapper {
            width: auto;
            display: flex;
            align-items: center;
            font-size: 1.6rem;
            text-transform: uppercase;
            border: 1px solid $color-grey-7;
            padding: 1rem 2rem 1rem 1rem;
            border-radius: 2rem;
            text-decoration: none;
            outline: none;
            color: $color-grey-7;
            transition: 0.2s;

            &:hover {
                color: $color-grey-1;
                border-color: $color-grey-1;
            }
        }
    }

    &-center {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: $dim-breakpoint-lg;
        width: 100%;
        padding-bottom: 4rem;

        &.row {
            flex-direction: column;

            @media (min-width: $dim-breakpoint-md-lg) {
                flex-direction: row;
                align-items: flex-start;
            }
        }
    }

    &__header {
        font-size: 4rem;
        padding: 2rem 0;
        text-align: center;

        @media (min-width: $dim-breakpoint-mdbg) {
            font-size: 6rem;
        }

        &.borderbottom {
            width: auto;
            border-bottom: 1px solid $color-grey-3;
            margin: 0 2rem;
        }
    }

    &__subheader {
        font-size: 2rem;
        color: $color-grey-1;
        text-align: center;
        padding: 0 2rem 6rem;
    }

    &-container {
        display: flex;
        padding-bottom: 10rem;
    }

    &__text {
        padding: 1.5rem;
        padding-top: 2rem;
        color: $color-grey-5;
        line-height: 1.5em;
        font-size: 1.4rem;

        &.paddingtopbig {
            padding-top: 4rem;
        }

        @media (min-width: $dim-breakpoint-md-lg) {
            font-size: 1.7rem;
        }
    }

    &__photo {
        display: flex;
        justify-content: center;
        padding: 2rem;
        width: 100%;
        

        &.small {
            padding: 1rem 2.5rem;
        }

        &-img {
            max-width: 100%;
            height: auto;
            border-radius: 1rem;
        }
    }
    &__link {
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 1.4rem;
        color: blue;
        text-decoration: underline;
        @media (min-width: $dim-breakpoint-md-lg) {
            font-size: 1.7rem;
        }
    }

    &-list {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        width: 100%;
        flex-wrap: wrap;
        padding: 0;

        @media(min-width: $dim-breakpoint-md) {
            padding: 1rem;
        }

        &__cell {
            display: flex;
            flex-direction: column;
            border: 1px solid $color-grey-3;
            background-color: $color-bg;
            transition: 0.2s;
            width: 100%;
            border-radius: 1rem;

            &:hover {
                box-shadow: $box-shadow-1;
                transform: scale(1.02);
            }

            &-wrapper {
                display: flex;
                padding: 2rem 2rem;
                width: 100%;
                text-decoration: none;
                color: $color-bg-black;
                flex: 0 0 100%;

                @media (min-width: $dim-breakpoint-md) {
                    flex: 0 0 50%;
                    padding: 1rem;
                }

                @media (min-width: $dim-breakpoint-md-lg) {
                    flex: 0 0 1/3 * 100%;
                }
            }

            &-info {
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 2rem;

                &__title {
                    font-size: 2.2rem;
                    padding-bottom: 1rem;
                    font-weight: bold;
                }

                &__created {
                    display: flex;
                    justify-content: space-between;
                    color: $color-grey-1;
                    padding-bottom: 2rem;
                    font-size: 1.2rem;

                    &-when {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;

                        &__icon {
                            @include ff-icon;
                            font-size: 1rem;
                            padding-right: 0.25rem;
                        }
                    }
                }
            }

            &-description {
                display: flex;
                align-items: flex-end;
                font-size: 1.5rem;
                padding: 0 2rem 2rem;
                flex: 1 0 0;
            }

            &__photo {
                width: 100%;

                &-image {
                    border-radius: 1rem 1rem 0 0;
                    width: 100%;
                }
            }

            &-buttons {
                width: auto;
                display: flex;
                padding: 0 2rem 2rem;
            }
        }
    }

    &-points {
        color: $color-grey-5;
        line-height: 1.5em;
        font-size: 1.4rem;
        padding-right: 1.5rem;
        padding-top: 2rem;

        :last-child {
            padding-bottom: 0;
        }

        li {
            padding-bottom: 2rem;
        }

        @media (min-width: $dim-breakpoint-md-lg) {
            font-size: 1.7rem;
        }
    }

    &-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 2rem;

        &-center {
            display: flex;
            flex-direction: column;
        }

        &__header {
            font-size: 1.5rem;
            text-transform: uppercase;
            letter-spacing: 0.2rem;
            color: $color-grey-2;
            padding: 1rem 0;
            border-bottom: 1px solid $color-grey-2;
        }

        &__searchbar {
            display: flex;
            align-items: center;
            margin: 2rem 0;
            padding: 1rem;
            border: 1px solid $color-grey-3;
            background-color: $color-grey-4;
            border-radius: 2rem;
            color: $color-grey-1;
            font-size: 1.4rem;

            &__icon {
                @include ff-icon;
                padding: 0 1rem;
                color: $color-grey-1;
            }
        }

        &-list {
            width: 100%;
            padding-top: 1rem;

            &__cell {
                padding: 2rem 0;
                border-bottom: 1px solid $color-grey-3;
                font-size: 1.5rem;
                font-weight: lighter;
            }
        }
    }

    &__categories {
        width: 100%;
        padding: 1rem 2rem;
    }
}
