$width-nav-links-big: 805px;
$width-nav-links-medium: 705px;
$width-nav-links-small: 534px;
$width-logo: 64px;
$width-social: 51px;
$width-language: 63px;

$breakpoint-nav-center: 1050px;

._nav {
  background-color: $color-bg;
  height: 8rem;
  position: fixed;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: stretch;
  top: 0;
  left: 0;
  z-index: 10;
  box-shadow: 0 0 1rem 0.1rem hsla(0, 0%, 40%, 0.15);
  transition: all 0.2s ease-in-out;

  &.see-through {
    @media (min-width: $dim-breakpoint-mdbg) {
      background-color: rgba($color-bg, 0);
      box-shadow: 0 0 0 0 hsla(0, 0%, 40%, 0.15);
    }
  }

  &__center {
    width: 100%;
    max-width: $dim-breakpoint-lg;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;

    @media (min-width: $dim-breakpoint-mdbg) {
      order: 1;
    }

    &::before {
      content: "";
      position: absolute;
      height: 8rem;
      width: 100%;
      background-color: white;
      z-index: 2;

      @media (min-width: $dim-breakpoint-mdbg) {
        display: none;
      }
    }
  }

  &__logo {
    width: 6.4rem;
    height: 6.4rem;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    margin-left: 1rem;
    z-index: 20;

    @media (min-width: $dim-breakpoint-lg) {
      margin-left: 0;
    }

    &-img {
      position: relative;
      width: 7.4rem;
      height: 7.4rem;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__right {
    flex: 1 0 18rem;
    display: flex;
    justify-content: flex-end;
    height: 8rem;
    z-index: 3;

    &-button {
      padding: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Material Icons, sans-serif;
      font-size: 2.5rem;
      color: $color-primary;
    }

    @media (min-width: $dim-breakpoint-mdbg) {
      display: none;
    }
  }

  &__links {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    transform: translate(0, -180%);
    transition: 0.2s;
    flex: 1 0 75%;
    background-color: $color-bg;

    @media (min-width: $dim-breakpoint-mdbg) {
      display: flex;
      flex-direction: row;
      flex: 1 0 50%;
      transform: translate(0, 0);
      box-shadow: none;

      order: 2;
      justify-content: center;
      align-items: center;
      background-color: transparent;
    }

    @media (min-width: $breakpoint-nav-center) {
      position: absolute;
      width: 100%;
      justify-content: center;
    }

    &-left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 3rem;

      @media (min-width: $dim-breakpoint-mdbg) {
        flex-direction: row;
        padding-bottom: 0;
      }

      @media (min-width: $breakpoint-nav-center) {
      }
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-top: 0rem;
      border-top: 1px solid $color-grey-3;

      @media (min-width: $dim-breakpoint-mdbg) {
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        border-top: none;
        //padding: 0 0 0 0.5rem;
      }

      @media (min-width: $breakpoint-nav-center) {
        justify-content: space-between;
        width: calc(50% - #{$width-nav-links-medium/2});
        position: absolute;
        right: 0;
      }

      /*@media (min-width: $dim-breakpoint-md-lg) {
                justify-content: space-between;
                width: calc(50% - #{$width-nav-links-medium/2});
                position: absolute;
                right: 0;
            } */

      @media (min-width: $dim-breakpoint-lg) {
        justify-content: space-between;
        width: calc(50% - #{$width-nav-links-big/2});
        position: absolute;
        right: 0;
      }

      &__empty {
        display: none;

        @media (min-width: $breakpoint-nav-center) {
          display: flex;
          flex: 0 0 1px;
          width: 1px;
        }
      }
    }
  }

  &__link {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: 500;
    text-decoration: none;
    text-align: center;
    color: $color-bg-black;
    border: none;
    background-color: transparent;
    padding: 0.5rem 1rem;
    outline: none;
    transition: 0.2s;
    cursor: normal;

    @media (min-width: $dim-breakpoint-md) {
      padding: 1rem 1rem;
    }

    @media (min-width: $dim-breakpoint-mdbg) {
      padding: 0.5rem 0.5rem 1rem;
      font-size: 1.6rem;
    }

    @media (min-width: $dim-breakpoint-md-lg) {
      padding: 0.5rem 2rem 1rem;
    }
    @media (min-width: $dim-breakpoint-lg) {
      padding: 0.5rem 3rem 1rem;
    }

    &-button {
      display: flex;
      transition: 0.2s;
      cursor: pointer;
      text-decoration: none;
      border-radius: 1.1rem;
      color: $color-bg-black;
      padding: 0.75rem 1rem;

      &.border {
        border: 1px solid $color-primary;
        color: $color-primary;
      }

      &:hover {
        background-color: $color-primary;
        border-radius: 2rem;

        padding: 0.75rem 1rem;

        //margin: 0 -1rem;

        color: white;

        @media (min-width: $dim-breakpoint-lg) {
          padding: 0.75rem 1rem;

          //margin: 0 -1.5rem;
        }
      }

      &.is-deep-active {
        background-color: $color-primary;
        padding: 1rem;
        // margin: 0 -1rem;
        color: white;

        @media (min-width: $dim-breakpoint-lg) {
          padding: 1rem 1rem;
          //margin: 0 -1.5rem;
        }
      }
    }

    &-wrapper {
      display: flex;
      align-items: center;
    }

    &-icon {
      @include ff-icon;
      font-size: 1rem;
      padding-right: 1rem;
      padding-left: 1rem;
      color: turquoise;
      cursor: pointer;
    }

    &-dot {
      display: none;
      width: 0.6rem !important;
      height: 0.6rem;
      background-color: $color-primary;
      border-radius: 0.3rem;
      padding-right: 0.5rem;

      @media (min-width: $dim-breakpoint-mdbg) {
        display: flex;
      }
    }

    &-language-switch {
      display: flex;
      justify-content: center;
      align-self: center;
      font-size: 1.6rem;
      height: 100%;
      padding: 1rem 0 1rem;
      height: 4rem;
      padding-right: 3rem;

      @media (min-width: $dim-breakpoint-mdbg) {
        padding: 0;
      }
    }

    &-socialmedia {
      display: flex;
      padding-bottom: 0rem;
      height: 100%;
      padding: 1rem 0 1rem;

      @media (min-width: $dim-breakpoint-mdbg) {
        padding: 0;
        padding-bottom: 0.5rem;
      }

      @media (min-width: $dim-breakpoint-md-lg) {
      }

      &-icon {
        display: flex;
        align-items: center;
        font-size: 2.6rem;
        color: $color-grey-2;
        font-weight: bold;
        text-decoration: none;
        width: 3rem;
        height: 4rem;
        justify-content: center;
        align-items: center;

        &:hover {
          color: $color-primary;
        }
      }
    }
  }

  &__link-language-switch {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
  }

  &.is-open {
    ._nav {
      &__links {
        display: flex;
        transform: translate(0, 0);
        transition: 0.2s;
        box-shadow: $box-shadow-2;

        @media (min-width: $dim-breakpoint-mdbg) {
          border-bottom: none;
          box-shadow: none;
        }

        &-right {
          @media (min-width: $dim-breakpoint-mdbg) {
            border-top: none;
          }
        }
      }
    }
  }

  &.makietanav {
    background-color: transparent;
    box-shadow: none;

    ._nav {
      &__logo {
        margin-left: 0;
        justify-content: flex-start;
      }

      &__center {
        justify-content: space-between;
        padding: 2rem 1.5rem;
        max-width: none;

        &::before {
          display: none;
        }
      }

      &__right {
        display: none;
      }

      &__links {
        flex: none;
        transform: translate(0, 0);
        background-color: transparent;
        width: auto;
        position: relative;
      }

      &__links-left {
        display: none;
      }

      &__links-right {
        width: auto;
        border-radius: 0.5rem;
        margin-right: 0.5rem;
        flex-direction: row;
        position: relative;
        background-color: $color-bg;
        box-shadow: $box-shadow-2;
        margin-top: -1rem;

        &__empty {
          display: none;
        }
      }

      &__link-language-switch {
        padding: 0;
        padding-right: 1.5rem;
        padding-left: 0.3rem;
      }

      &__link-socialmedia {
        padding: 0;
        padding-right: 0.5rem;

        &-icon {
          font-size: 2.5rem;
          padding-bottom: 0.35rem;
          width: 3rem;

          &:hover {
            color: $color-secondary;
          }
        }
      }

      &__link {
        &.lang {
          padding: 0;
          width: 3.5rem;
          &:hover {
            color: $color-secondary;
          }

          &.selected {
            color: $color-secondary;
          }
        }
      }
    }
  }
}

.lang {
  color: $color-grey-1;
  padding: 0 0.5rem;
  height: 4rem;
  width: 3.5rem;
  cursor: pointer;

  &-de {
    color: $color-grey-1;
    padding: 0 0.5rem;
    height: 4rem;
    width: 3.5rem;
    cursor: pointer;

    &:hover {
      color: $color-primary;
    }

    .makietanav & {
      display: none;
    }
  }

  &:hover {
    color: $color-primary;
  }
}

.selected {
  font-weight: bold;
  color: $color-primary;
}

.router-home {
  width: 100%;
  min-height: 100%;

  &.is-disabled-scroll {
    overflow: hidden;
  }
}
