.contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $color-bg-black;
    width: 100%;
    color: $color-bg;
    position: relative;

    &.padding-top {
        padding-top: 2rem;
    }

    &__arrow {
        width: 100%;
        height: 100%;
        fill: $color-bg-black;
    }

    &-bg {
        width: 100%;
        position: relative;
        padding: 2rem;
        background-color: $color-bg-black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &-top {
            width: 100%;
            height: 4vw;
            position: absolute;
            overflow: hidden;
            top: -3vw;
            @media (min-width: $dim-breakpoint-md-lg) {
                height: 2vw;
                top: -1.5vw;
            }
        }
    }

    &-center {
        display: flex;
        flex-direction: column;
        max-width: $dim-breakpoint-lg;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    &__box {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        max-width: $dim-breakpoint-md-lg;
        width: 100%;
        flex-wrap: wrap;

        &-column {
            display: flex;
            flex-direction: column;
            flex: 0 0 90%;
            padding-bottom: 2rem;

            @media (min-width: $dim-breakpoint-mdbg) {
                flex: 0 0 57%;
                padding: 1rem 2rem;

                &.small {
                    flex: 0 0 43%;
                }
            }

            @media(min-width: $dim-breakpoint-md-lg) {
                &.small {
                    padding-left: 10rem;
                }
            }

            &-wrapper {
                display: flex;
                flex-direction: column;
                position: relative;
                width: 100%;

                &__overlay {
                    position: absolute;
                    background-color: $color-bg-black;
                    opacity: 0.85;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    &-content {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                    }

                    &-header {
                        display: flex;
                        align-items: center;
                        font-size: 2.2rem;
                        color: $color-grey-6;
                        padding-bottom: 2rem;
                    }

                    &-icon {
                        @include ff-icon;
                        padding-right: 0.5rem;
                        padding-top: 0.25rem;
                        color: $color-grey-6;
                    }

                    &-buttons {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                    }
                }
            }

            &__header {
                font-size: 2.4rem;
                font-weight: 300;
                color: $color-primary;
                padding-bottom: 1rem;
            }

            &__subheader {
                font-size: 1.6rem;
                padding-bottom: 2rem;
                padding-top: 1rem;
                line-height: 1.5em;
                font-weight: 300;
            }

            &__input {
                width: 100%;
                outline: none;
                border: 1px solid $color-primary;
                border-radius: 1.25rem;
                padding: 1.25rem 1.5rem;
                font-size: 1.6rem;
                margin-bottom: 2rem;
                background-color: inherit;
                color: $color-grey-2;
                font-weight: 300;
                @include ff-text;

                @media (max-width: $dim-breakpoint-md) {
                    font-size: 1.6rem;
                }

                &::placeholder {
                    color: $color-grey-2;
                }

                &.small {
                    width: 70%;
                }
            }

            &__textare {
                width: 100%;
                border: 1px solid $color-primary;
                border-radius: 1.25rem;
                padding: 1rem 0;
                margin-bottom: 2rem;
                @include ff-text;
                
            }

            &__textarea {
                width: 100%;
                border: none;
                outline: none;
                min-height: 10rem;
                background-color: inherit;
                color: $color-grey-2;
                padding: 0 1.5rem;
                font-size: 1.6rem;
                resize: none;
                font-weight: 300;
                @include ff-text;

                @media (max-width: $dim-breakpoint-md) {
                    font-size: 1.6rem;
                }

                @media (min-width: $dim-breakpoint-md) {
                    min-height: 10rem;
                }

                &::placeholder {
                    color: $color-grey-2;
                }

                &::-webkit-scrollbar {
                    width: 0.5rem;
                    cursor: pointer;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $color-primary;
                    border-radius: 1rem;
                    box-shadow: $box-shadow-7;
                    cursor: pointer;
                }
            }

            &__buttons {
                padding: 1rem 0;
            }

            &-info {
                display: flex;
                flex-direction: column;
                width: 100%;
                padding-bottom: 1rem;

                &-row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 1rem 0;

                    &.column {
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    &__icon {
                        @include ff-icon;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 4rem;
                        height: 4rem;
                        border: 1px solid $color-grey-2;
                        border-radius: 2rem;
                        color: $color-grey-2;
                    }

                    &__content {
                        padding-left: 1.5rem;
                        font-size: 1.6rem;
                        color: $color-grey-2;
                        font-weight: 300;
                    }

                    &__city {
                        font-size: 1.6rem;
                        font-weight: bold;
                    }

                    &__address {
                        font-size: 1.6rem;
                        padding-top: 0.2rem;
                        font-weight: 300;
                    }
                }
            }

            &__smalltext {
                display: flex;
                font-size: 1rem;
                color: $color-grey-2;
                padding-bottom: 1rem;
                font-weight: 300;
            }
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        text-align: center;
        max-width: $dim-breakpoint-md-lg;
        width: 100%;
        border-top: 1px solid $color-grey-1;
        padding-top: 2rem;

        &-text {
            display: flex;
            width: 100%;
            color: $color-grey-1;
            font-size: 1.3rem;
            padding-bottom: 1rem;
        }

        &-policy {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: flex-start;
            text-align: center;
            font-size: 1.3rem;
            color: $color-grey-7;

            &-link {
                padding-left: 0.5rem;
                color: $color-primary;
                text-decoration: underline;
            }
        }
    }

    &.flat-top {
        margin-top: 0rem;
        .contact-bg-top {
            display: none;
        }
    }

    &__checkbox {
        display: flex;
        align-items: center;
        padding: 1rem 0;

        &-input {
            margin-right: 1rem;
        }
    }

    &__field-error {
        color: red;
        font-size: 1.1rem;
        margin-top: -1rem;
        padding-bottom: 1.75rem;
    }

    &__checkbox {
        padding: 0.5rem 0;
    }
}
