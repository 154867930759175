.technology {
    width: 100%;
    height: auto;
    display: flex;
    padding: 1rem 2rem;
    color: $color-primary;
    justify-content: center;

    @media(min-width: $dim-breakpoint-md) {
        height: 100vh;
    }

    &-center {
        max-width: $dim-breakpoint-lg;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        flex-direction: column;
    }

    &-header {
        display: flex;
        font-size: 3rem;
        justify-content: center;
        font-weight: bold;
        text-transform: uppercase;
        padding-bottom: 2rem;
    }

    &-containers {
        display: flex;
        width: 100%;
        align-items: center;
        flex-wrap: wrap;
    }

    &-container {
        display: flex;
        font-size: 1.5rem;
        flex-direction: column;
        flex: 0 0 100%;
        padding: 1rem 1.5rem;

        @media (min-width: $dim-breakpoint-md) {
            flex: 0 0 1/2*100%;
        }

        @media (min-width: $dim-breakpoint-lg) {
            flex: 0 0 1/3*100%;
        }

        &__content {
            padding: 1.5rem;
            border: 1px solid $color-grey-1;
            border-radius: 1rem;

            &:hover {
                border-color: $color-primary;
            }
        }

        &__header {
            text-transform: uppercase;
            font-weight: bold;
            padding: 1rem 0;
        }
    }
}