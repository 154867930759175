.button {
    border-radius: 2rem;
    padding: 1rem 2rem;
    font-size: 1.5rem;
    border: 2px solid $color-primary;
    color: $color-bg-elem;
    background-color: $color-primary;
    cursor: pointer;
    text-align: center;
    outline: none;
    text-decoration: none;
    transition: 0.2s;

    :active {
        outline: none;
        background-color: transparent;
    }

    &:hover {
        zoom: 1;
        box-shadow: $box-shadow-6;
    }

    &.icon {
        @include ff-icon;
    }

    &.clear {
        background-color: transparent;
        color: $color-primary;
        border: none;
    }

    &.white {
        background-color: $color-bg;
        border: 1px solid $color-bg;
        color: $color-bg-black;
        font-size: 1.6rem;
        padding: 0.4em 2.5em 0.8rem;
        border-radius: 3rem;

        &:hover {
            box-shadow: $box-shadow-10;
        }
    }

    &.grey {
        background-color: transparent;
        border-color: $color-primary;
        color: $color-primary;
        border: 1px solid $color-primary;
        border-radius: 2rem;
        padding: 1rem 2rem;
        font-size: 1.4rem;
        transition: 0.2s;

        &:hover {
            color: $color-bg-black;
            border: 1px solid $color-grey-1;
            color: $color-grey-1;
            box-shadow: none;
        }
    }

    &.light {
        background-color: transparent;
        border-color: $color-primary;
        color: $color-primary;
        border: 1px solid $color-primary;
        border-radius: 2rem;
        padding: 1rem 2rem;
        font-size: 1.4rem;
        transition: 0.2s;

        &:hover {
            color: $color-bg;
            border: 1px solid $color-primary;
            background-color: $color-primary;
            box-shadow: none;
        }

        &:active {
            outline: none;
            background-color: transparent;
        }
    }

    &.higher {
        padding: 1rem 2rem 1.3rem;
    }

    &.lighter {
        background-color: transparent;
        border-color: $color-primary;
        color: $color-primary;
        border: 1px solid $color-primary;
        border-radius: 2rem;
        padding: 1rem 2rem;
        font-size: 1.5rem;
        transition: 0.2s;

        &:hover {
            color: $color-bg;
            border: 1px solid $color-primary;
            background-color: $color-primary;
            box-shadow: $box-shadow-12;
        }
    }

    &.big {
        font-size: 1.7rem;
        padding: 1.25rem 5rem;
        border-radius: 5rem;
    }

    &.link {
        padding: 0em;
        background-color: transparent;
        border: none;
        color: $color-primary;
        font-size: 1.5rem;
        text-decoration: underline;

        &-small {
            font-size: 1.3rem;
            padding: 0em;
            background-color: transparent;
            border: none;
            color: $color-primary;
            text-decoration: underline;
        }

        &:hover {
            box-shadow: none;
        }
    }

    &.category {
        background-color: $color-grey-3;
        color: $color-grey-1;
        border-color: $color-grey-3;

        &:hover {
            background-color: $color-grey-4;
            color: $color-grey-1;
            box-shadow: $box-shadow-11;
            border-color: $color-grey-3;
        }
    }

    &.iframe {
        @include ff-icon;
        padding: 1rem;
        font-size: 3rem;
    }

    &.small {
        padding: 1rem;
        width: auto;
        font-weight: bold;
    }

    &.retry {
        border: 1px solid $color-grey-6;
        padding: 1rem 2rem;
        background-color: transparent;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;
        display: flex;
        align-items: center;
        font-size: 1.4rem;

        &:hover {
            box-shadow: $box-shadow-9;
        }
    }
}
