/*.start {
    max-width: $dim-breakpoint-lg;
    width: 100%;
    display: flex;
    // padding: 1rem 0;
    padding-top: 8rem;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-header {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 2rem 1rem;
        background-color: rgb(0, 170, 153);
        color: white;
        width: 100%;

        &__primary {
            font-size: 2.5rem;
            z-index: 1;
            padding-left: 0;
        }

        &__secondary {
            font-size: 2rem;
            padding-left: 0;
            z-index: 1;
        }
    }
    &-buttons {
        z-index: 1;
        padding-left: 0;
    }

    &-image {
        display: flex;
        width: 100%;
        // height: 100%;
        flex-direction: column;

        justify-content: center;

        &__background {
            padding-top: 100%;
            width: 100%;
            // height: 100%;
            background-image: url("../../assets/mac1.jpeg");
            background-repeat: no-repeat;
            background-position: 52% bottom;
            background-size: 250% auto;
        }
    }

    &-center {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        flex-direction: column;
    }

    @media (min-width: $dim-breakpoint-md) {
        padding-top: 8rem;
        flex-direction: column;
        justify-content: start;

        &-header {
            width: auto;
            text-align: left;

            &_secondary {
                font-size: 1.2rem;
            }
        }

        &-image {
            display: flex;
            position: absolute;
            width: 100%;
            justify-content: flex-end;
            align-items: center;

            &__background {
                width: 100%;
                background-image: url("../../assets/mac1.jpeg");
                background-position: 00% bottom;
                background-size: 150% auto;
                background-repeat: no-repeat;
                // transform: scale(1.5);
            }
        }

        &-center {
            flex-direction: row;
            align-items: center;
        }
    }

    @media (min-width: $dim-breakpoint-lg) {
        &-image {
            &__background {
            }
        }
        display: flex;
        justify-content: center;
    }
}
*/

.start {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 8rem;
    justify-content: center;
    position: relative;

    @media (min-width: $dim-breakpoint-md) {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    &__wrapper {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    &__overlay {
        top: 0;
        left: 0;
        position: absolute;
        transition: opacity 0.4s ease-out;
        width: 100%;
        height: 100%;
        pointer-events: none;
        background-color: $color-primary;
        opacity: 0;

        &.show {
            opacity: 1;

            transition: opacity 0s linear;
        }
    }

    &__header {
        z-index: 3;
        display: flex;
        flex-direction: column;
        top: 0;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: left;
        background-color: $color-primary;
        opacity: 0.9;
        color: white;
        padding: 2rem;

        @media (min-width: $dim-breakpoint-md) {
            width: 39%;
        }

        @media (min-width: $dim-breakpoint-md) {
            position: absolute;
            top: 20%;
            left: 1%;
        }

        @media (min-width: $dim-breakpoint-mdbg) {
            width: 39%;
            left: 1%;
            top: 23%;
            padding: 2.5rem 0 2.5rem 2.5rem;
        }

        @media (min-width: $dim-breakpoint-md-lg) {
            width: 36%;
            top: 24%;
            left: 3%;
            padding: 3.5rem 0 3.5rem 3rem;
        }

        @media (min-width: $dim-breakpoint-lgsm) {
            width: 34%;
            top: 25%;
            left: 5%;
            padding: 4rem 0 4rem 3.5rem;
        }

        @media (min-width: $dim-breakpoint-lg) {
            width: 35%;
            left: 8%;
            top: 15%;
            padding: 4.5rem 0 4.5rem 4rem;
        }

        @media (min-width: 1400px) {
            width: 30%;
            top: 20%;
        }

        @media (min-width: 1600px) {
            width: 28%;
            left: 15.5%;
            height: 43%;
            top: 16%;
            // padding-top: rem;
            justify-content: flex-end;
        }

        // @media (min-width: 1850px) {
        //     width: 24%;
        //     left: 18%;
        //     top: 20%;
        // }

        @media (min-width: 2100px) {
            width: 25%;
            left: 17%;
            top: 16%;
        }

        &__space {
            width: 100%;
            padding-top: 1.5rem;

            @media (min-width: $dim-breakpoint-lgsm) {
                padding-top: 3rem;
            }

            @media (min-width: 1600px) {
                padding-top: 4rem;
            }
        }

        &-wrapper {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &__primary {
            width: 100%;
            font-size: 2.5rem;
            font-weight: bold;
            padding-bottom: 0.5rem;
            padding-left: 1rem;

            @media (min-width: $dim-breakpoint-sm) {
                font-size: 3rem;
            }

            @media (min-width: $dim-breakpoint-md) {
                font-size: 1.7rem;
            }

            @media (min-width: $dim-breakpoint-mdbg) {
                font-size: 2rem;
            }

            @media (min-width: $dim-breakpoint-md-lg) {
                font-size: 2rem;
            }

            @media (min-width: $dim-breakpoint-lgsm) {
                font-size: 2.2rem;
            }

            @media (min-width: $dim-breakpoint-lg) {
                font-size: 2.9rem;
                padding-left: 0;
            }

            @media (min-width: 1600px) {
                font-size: 3.2rem;
            }
        }

        &__secondary {
            width: 100%;
            font-size: 1.6rem;
            padding-left: 1rem;
            font-weight: 300;

            @media (min-width: $dim-breakpoint-sm) {
                font-size: 1.9rem;
            }

            @media (min-width: $dim-breakpoint-md) {
                font-size: 1.1rem;
            }

            @media (min-width: $dim-breakpoint-mdbg) {
                font-size: 1.3rem;
            }

            @media (min-width: $dim-breakpoint-md-lg) {
                font-size: 1.5rem;
            }

            @media (min-width: $dim-breakpoint-lgsm) {
                font-size: 1.66rem;
            }

            @media (min-width: $dim-breakpoint-lg) {
                font-size: 1.8rem;
                padding-left: 0;
            }

            @media (min-width: 1600px) {
                font-size: 2.06rem;
            }
        }

        &__scroll {
            padding-top: 1.5rem;
            width: 100%;
            display: flex;
            flex-direction: row;
            text-align: left;

            @media (min-width: $dim-breakpoint-lgsm) {
                padding-top: 3rem;
            }

            @media (min-width: 1600px) {
                padding-top: 4rem;
            }

            &_icon {
                cursor: pointer;
                @include ff-icon;
                padding-right: 2rem;
                user-select: none;

                @media (min-width: $dim-breakpoint-sm) {
                    font-size: 3rem;
                }

                @media (min-width: $dim-breakpoint-md) {
                    font-size: 2.5rem;
                }

                @media (min-width: $dim-breakpoint-md-lg) {
                    font-size: 3rem;
                }

                @media (min-width: $dim-breakpoint-lgsm) {
                    font-size: 3.5rem;
                }

                @media (min-width: 1600px) {
                    font-size: 4rem;
                }
            }
        }
    }

    &__monitor {
        position: absolute;
        background-color: black;
        top: 18.5%;
        left: 6.8%;
        width: 88%;
        height: 49.3%;

        // @media (min-width: $dim-breakpoint-sm) {
        //     top: 30.5%;
        //     left: 6.8%;
        //     width: 87.85%;
        //     height: 41%;
        // }

        @media (min-width: $dim-breakpoint-md) {
            top: 12.3%;
            left: 43.6%;
            width: 52.3%;
            height: 49%;
        }

        @media (min-width: $dim-breakpoint-lg) {
            top: 9.5%;
            left: 49.2%;
            width: 46.8%;
            height: 51%;
        }

        @media (min-width: 1600px) {
            top: 9.8%;
            left: 52%;
            width: 38.6%;
            height: 50.2%;
        }

        &-clicker {
            width: 100%;
            height: 100%;
            background-image: url("../../assets/london.jpg");
            background-size: cover;
            display: flex;
            align-items: center;
            justify-content: center;

            @media(min-width: $dim-breakpoint-md) {
                position: relative;
                display: none;
            }
        }

        &-iframe {
            display: none;
            width: 100%;
            height: 100%;

            @media(min-width: $dim-breakpoint-md) {
                position: relative;
                display: block;
            }
        }
    }

    &__image {
        width: 100%;
        padding-top: 100%;
        background-repeat: no-repeat;
        background-image: url("../../assets/mac2.jpg");
        background-repeat: no-repeat;
        background-position: 51% 90%;
        background-size: 320% auto;
        position: relative;

        @media (min-width: $dim-breakpoint-sm) {
            padding-top: 100%;
        }

        @media (min-width: $dim-breakpoint-md) {
            padding-top: 60%;
            background-position: 30% bottom;
            background-size: 190% auto;
        }

        @media (min-width: $dim-breakpoint-lg) {
            padding-top: 52%;
            background-position: 20% bottom;

            background-size: 170% auto;
        }

        @media (min-width: 1600px) {
            padding-top: 43%;
            background-position: 0% bottom;
            background-size: 140% auto;
        }
    }

    &__mouse {
        display: none;

        @media (min-width: $dim-breakpoint-md) {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 5vw;
        }

        &-img {
            width: 3vw;
            max-width: 5rem;
        }
    }
}
