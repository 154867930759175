html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-size: 10px;
    min-width: 320px;
}

body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 10px;
    width: 100%;
    height: 100%;
    min-width: 320px;
    background-color: $color-bg;
    @include ff-text();
}

#root {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}