$dt: 5%;

@mixin t($x) {
    transform: translate($x, 0);
}

@keyframes logo-0 {
    0% {
        @include t(0);
    }
    #{$dt} {
        @include t(-100%);
    }
    #{$dt + 1%} {
        opacity: 1;
        @include t(-100%);
    }
    #{$dt + 2%} {
        opacity: 0;
        @include t(-100%);
    }
    #{$dt + 3%} {
        opacity: 0;
        @include t(400%);
    }
    #{$dt + 4%} {
        opacity: 1;
        @include t(400%);
    }
    20% {
        @include t(400%);
    }
    #{$dt + 20%} {
        @include t(300%);
    }
    40% {
        @include t(300%);
    }
    #{$dt + 40%} {
        @include t(200%);
    }
    60% {
        @include t(200%);
    }
    #{$dt + 60%} {
        @include t(100%);
    }

    80% {
        @include t(100%);
    }
    #{$dt + 80%} {
        @include t(0%);
    }
    100% {
        @include t(0%);
    }
}

@keyframes logo-1 {
    0% {
        @include t(0);
    }
    #{$dt} {
        @include t(-100%);
    }
    20% {
        @include t(-100%);
    }
    #{$dt + 20%} {
        @include t(-200%);
    }
    #{$dt + 21%} {
        opacity: 1;
        @include t(-200%);
    }
    #{$dt + 22%} {
        opacity: 0;
        @include t(-200%);
    }
    #{$dt + 23%} {
        opacity: 0;
        @include t(300%);
    }
    #{$dt + 24%} {
        opacity: 1;
        @include t(300%);
    }
    40% {
        @include t(300%);
    }
    #{$dt + 40%} {
        @include t(200%);
    }
    60% {
        @include t(200%);
    }
    #{$dt + 60%} {
        @include t(100%);
    }

    80% {
        @include t(100%);
    }
    #{$dt + 80%} {
        @include t(0%);
    }
    100% {
        @include t(0%);
    }
}

@keyframes logo-2 {
    0% {
        @include t(0);
    }
    #{$dt} {
        @include t(-100%);
    }

    20% {
        @include t(-100%);
    }
    #{$dt + 20%} {
        @include t(-200%);
    }
    40% {
        @include t(-200%);
    }
    #{$dt + 40%} {
        @include t(-300%);
    }
    #{$dt + 41%} {
        opacity: 1;
        @include t(-300%);
    }
    #{$dt + 42%} {
        opacity: 0;
        @include t(-300%);
    }
    #{$dt + 43%} {
        opacity: 0;
        @include t(200%);
    }
    #{$dt + 44%} {
        opacity: 1;
        @include t(200%);
    }
    60% {
        @include t(200%);
    }
    #{$dt + 60%} {
        @include t(100%);
    }

    80% {
        @include t(100%);
    }
    #{$dt + 80%} {
        @include t(0%);
    }
    100% {
        @include t(0%);
    }
}

@keyframes logo-3 {
    0% {
        @include t(0);
    }
    #{$dt} {
        @include t(-100%);
    }

    20% {
        @include t(-100%);
    }
    #{$dt + 20%} {
        @include t(-200%);
    }
    40% {
        @include t(-200%);
    }
    #{$dt + 40%} {
        @include t(-300%);
    }

    60% {
        @include t(-300%);
    }
    #{$dt + 60%} {
        @include t(-400%);
    }

    #{$dt + 61%} {
        opacity: 1;
        @include t(-400%);
    }
    #{$dt + 62%} {
        opacity: 0;
        @include t(-400%);
    }
    #{$dt + 63%} {
        opacity: 0;
        @include t(100%);
    }
    #{$dt + 64%} {
        opacity: 1;
        @include t(100%);
    }

    80% {
        @include t(100%);
    }
    #{$dt + 80%} {
        @include t(0%);
    }
    100% {
        @include t(0%);
    }
}

@keyframes logo-4 {
    0% {
        @include t(0);
    }
    #{$dt} {
        @include t(-100%);
    }
    20% {
        @include t(-100%);
    }
    #{$dt + 20%} {
        @include t(-200%);
    }
    40% {
        @include t(-200%);
    }
    #{$dt + 40%} {
        @include t(-300%);
    }

    60% {
        @include t(-300%);
    }
    #{$dt + 60%} {
        @include t(-400%);
    }

    80% {
        @include t(-400%);
    }
    #{$dt + 80%} {
        @include t(-500%);
    }

    #{$dt + 81%} {
        opacity: 1;
        @include t(-500%);
    }
    #{$dt + 82%} {
        opacity: 0;
        @include t(-500%);
    }
    #{$dt + 83%} {
        opacity: 0;
        @include t(0%);
    }
    #{$dt + 84%} {
        opacity: 1;
        @include t(0%);
    }
    100% {
        @include t(0%);
    }
}

.whotrusted {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $color-bg;
    width: 100%;
    color: $color-bg-black;

    margin-bottom: 10rem;

    &-center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: $dim-breakpoint-lg;
        width: 100%;
    }

    &__header {
        display: flex;
        justify-content: center;
        font-size: 4rem;
        max-width: $dim-breakpoint-md-lg;
        width: 100%;
        text-align: center;
        padding: 2rem 0 2rem;
        color: $color-primary;
        font-weight: lighter;
        font-weight: 300;

        @media (min-width: $dim-breakpoint-md-lg) {
            font-size: 5rem;
        }
    }

    &__opinions {
        display: flex;
        justify-content: center;
        max-width: $dim-breakpoint-lg;
        width: 100%;
        flex-wrap: wrap;
        padding-bottom: 10rem;

        max-width: 1000px;

        &-opinion {
            position: relative;
            display: flex;
            flex: 0 0 90%;
            padding: 2rem 0;
            transition: all 0.2s ease-out;

            &:hover {
                transform: translate(0, -0.5rem);
            }

            @media (min-width: $dim-breakpoint-md) {
                display: flex;
                flex: 0 0 50%;

                padding: 1rem 2rem;
            }

            &-absolute {
                @include ff-icon;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                width: 3rem;
                height: 3rem;
                border-radius: 1.5rem;
                color: white;
                font-size: 2rem;
                background-color: $color-primary;
                right: 10%;
                top: 3%;

                @media (min-width: $dim-breakpoint-sm) {
                    top: -1%;
                }
            }

            &-wrapper {
                display: flex;
                flex-direction: column;
                box-shadow: $box-shadow-5;
                padding: 2rem;
                cursor: pointer;

                &:hover {
                    box-shadow: $box-shadow-7;
                }
            }

            &-head {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding-bottom: 1rem;

                &__photo {
                    width: 5rem;
                    height: 5rem;
                    border-radius: 2.5rem;
                    margin-right: 2rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &-image {
                        width: 100%;
                        border-radius: 2.5rem;
                    }
                }

                &-header {
                    display: flex;
                    flex-direction: column;

                    &__title {
                        font-size: 2rem;
                        color: $color-primary;
                    }

                    &__company {
                        font-size: 1.4rem;
                        color: $color-grey-2;
                    }
                }
            }

            &-description {
                padding-bottom: 1rem;
                font-size: 1.4rem;

                @media (min-width: $dim-breakpoint-md-lg) {
                    font-size: 1.4rem;
                }
            }
        }
    }

    &__companies {
        display: flex;
        flex-direction: column;
        max-width: $dim-breakpoint-md-lg;
        width: 100%;
        overflow-x: hidden;

        &-row {
            display: flex;
            flex-direction: row;
            width: 250%;
            flex-wrap: wrap;
            align-items: center;

            @media (min-width: $dim-breakpoint-md) {
                width: 125%;
            }

            &__cell {
                display: flex;
                flex: 0 0 1/5 * 100%;
                animation-iteration-count: infinite;
                animation-duration: 20s;
                animation-name: logo-4;
                animation-delay: 4s;
                animation-timing-function: linear;
                padding: 2rem;
                width: 100%;

                &:nth-child(5n + 1) {
                    animation-name: logo-0;
                }
                &:nth-child(5n + 2) {
                    animation-name: logo-1;
                }
                &:nth-child(5n + 3) {
                    animation-name: logo-2;
                }
                &:nth-child(5n + 4) {
                    animation-name: logo-3;
                }

                &:nth-child(10n + 1),
                &:nth-child(10n + 5),
                &:nth-child(10n + 2),
                &:nth-child(10n + 3),
                &:nth-child(10n + 4) {
                    animation-delay: 6s;
                }

                &-photo {
                    width: 100%;

                    &-img {
                        width: 100%;
                    }
                }
            }
        }
    }
}
