@mixin title-right() {
    transform: translate(30%, 0);
    opacity: 0;
}

@mixin title-center() {
    transform: translate(0, 0);
    opacity: 1;
    transition: all 0.2s 0.2s ease-out;
}

.makieta {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    font-family: "Rubik", sans-serif;

    &__video {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        height: 100%;

        &-video {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    &__nav {
        z-index: 2;
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        height: 8rem;

        @media (min-width: $dim-breakpoint-md-lg) {
            padding: 2rem;
            height: 10rem;
        }

        &-logo {
            display: flex;
            width: 6rem;
            height: 6rem;

            &__photo {
                width: 100%;
                height: 100%;
            }
        }

        &-box {
            background-color: $color-bg;
            display: flex;
            padding: 1rem;
            border-radius: 0.5rem;

            &__lang {
                display: flex;
                padding-right: 1rem;
                font-size: 1.5rem;
                color: $color-grey-2;
                font-weight: 700;

                &-content {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 2.5rem;
                    height: 3.5rem;

                    &:hover {
                        color: $color-primary;
                    }
                }
            }

            &__social {
                display: flex;
                padding-left: 1rem;
                font-size: 2.25rem;
                font-weight: 700;
                color: $color-grey-2;

                &-content {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 2.15rem;
                    height: 3.5rem;
                }
            }
        }
    }

    &__policy {
        display: flex;
        flex-direction: column;
        color: $color-grey-5;
        font-size: 1rem;
        padding-top: 2rem;
        transition: opacity 0.2s ease-out;

        @media (min-width: $dim-breakpoint-md-lg) {
            position: absolute;
            bottom: 240px;
            background-color: rgba(white, 0.9);
            padding: 2rem;
            border-radius: 1rem;
            right: 2rem;
            max-width: 500px;
        }

        &.hide {
            @media (min-width: $dim-breakpoint-md-lg) {
                opacity: 0;
            }
        }

        &-footer {
            padding-top: 1rem;

            &-link {
                color: $color-grey-5;
            }
        }
    }

    &__middle {
        display: flex;
        z-index: 2;
        flex: 1 0 calc(100vh - 400px);
        min-height: 70vh;
        position: relative;
        padding-top: 10rem;
        align-items: center;
        overflow: hidden;
        background-color: rgba(black, 0.4);

        @media (min-width: 600px) {
            flex: 1 0 calc(100vh - 210px);
        }

        @media (min-width: 1000px) {
            min-height: 100vh;
            flex: 1 0 calc(100vh - 217px);
            padding-bottom: 200px;
        }

        &-item {
            transform: translate(-30%, 0);
            opacity: 0;
            position: absolute;
            width: 100%;
            left: 0;
            right: 0;
            text-align: center;
            transition: all 0.2s 0s ease-out;
            z-index: 5;
            font-family: "Montserrat", sans-serif;
            text-transform: uppercase;
            font-weight: 900;
            font-size: 7vw;
            color: $color-secondary;
            padding: 0 2rem;

            @media (min-width: 550px) {
                font-size: 4vw;
                padding: 0 5vw;
            }

            @media (min-width: 1000px) {
                font-size: 3vw;
            }

            $max: 9;
            @for $i from 1 to $max + 1 {
                &:nth-child(#{$i}) {
                    .item-#{$i} & {
                        @include title-center();
                    }
                    @if $i == 1 {
                        .item-#{$max} & {
                            @include title-right();
                        }
                    } @else {
                        .item-#{$i - 1} & {
                            @include title-right();
                        }
                    }
                }
            }
        }

        &-header {
            display: flex;
            position: absolute;
            font-family: "Montserrat", sans-serif;
            color: $color-secondary;
            font-weight: 900;
            text-transform: uppercase;
            font-size: 10vw;
            padding: 0 2rem;
            left: 0;
            right: 0;
            overflow: hidden;

            @media (min-width: 550px) {
                font-size: 5rem;
                position: relative;
                bottom: 0;
                width: 100%;
            }
        }
    }

    &__bottom {
        z-index: 2;
        display: flex;
        flex-direction: column;
        background-color: rgba($color-bg, 0.9);
        padding: 2rem 3rem;
        box-shadow: $box-shadow-2;

        @media (min-width: $dim-breakpoint-md-lg) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 1rem;
            padding: 2rem 4rem;
            position: fixed;
            bottom: 0;
            width: 100%;
        }

        &-form {
            width: 100%;
            display: flex;
        }

        &-checkboxes {
            display: flex;
            flex-direction: column;
            margin-top: 1rem;

            .form__checkbox {
                margin: 0.2rem 0;

                &-input {
                    width: 2rem;
                    height: 2rem;
                    flex: 0 0 2rem;
                }

                &-square {
                    border-color: $color-grey-2;
                    border-width: 1px;
                    width: 2rem;
                    height: 2rem;
                    font-size: 1.5rem;
                }

                &-label {
                    color: $color-grey-5;
                    font-weight: 400;
                    font-size: 0.8rem;
                }
            }
        }

        &-top {
            display: flex;
            flex-direction: column;
            padding: 1rem 0.75rem;
            border-left: 1px solid $color-grey-2;
            margin-bottom: 1rem;

            @media (min-width: $dim-breakpoint-md) {
                margin: 0;
                padding-right: 2rem;
            }

            &__header {
                font-size: 4.3rem;
                text-transform: uppercase;
                font-weight: 500;
                color: rgba(black, 0.8);
            }

            &__subheader {
                font-size: 1.6rem;
                text-transform: uppercase;
                //color: $color-grey-1;
                color: rgba(black, 0.8);
                font-weight: 400;
                word-spacing: 0.75rem;
            }
        }

        &-bottom {
            display: flex;
            flex-direction: column;
            width: 100%;
            z-index: 3;
            padding: 2rem 0 0;

            @media (min-width: $dim-breakpoint-md-lg) {
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                max-width: 65%;
                padding: 0;
            }

            &__container {
                position: relative;
                display: flex;
                flex-direction: column;
                padding: 1rem 0;
                width: 100%;

                max-width: 400px;

                @media (min-width: $dim-breakpoint-lg) {
                    flex: 0 0 400px;
                }

                .disabled {
                    opacity: 0.15;
                    pointer-events: none;
                }

                &-input {
                    padding: 1rem 2rem;
                    border: 1px solid $color-grey-2;
                    color: $color-grey-1;
                    border-radius: 0.5rem;
                    font-weight: 400;
                    text-transform: uppercase;
                    outline: none;
                    font-size: 1.6rem;
                    flex: 1 0 60%;
                    width: 50%;
                    font-family: "Rubik", sans-serif;
                    box-shadow: $box-shadow-1;

                    &.is-error {
                        border-color: red;
                    }

                    @media (min-width: $dim-breakpoint-md-lg) {
                    }

                    @media (min-width: $dim-breakpoint-lg) {
                    }
                }

                &-buttons {
                    position: relative;
                    padding-left: 1rem;
                    flex: 0 0 auto;
                    opacity: 1;

                    @media (min-width: $dim-breakpoint-md-lg) {
                        // flex: none;
                    }

                    &__button {
                        font-family: "Rubik", sans-serif;
                        padding: 1rem 2rem;
                        border: 1px solid $color-secondary;
                        background-color: $color-secondary;
                        color: $color-bg;
                        border-radius: 0.5rem;
                        font-size: 1.6rem;
                        font-weight: 500;
                        text-transform: uppercase;
                    }
                }

                &-overlay {
                    width: 100%;
                    height: 100%;
                    position: absolute;

                    &__content {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        &-header {
                            display: flex;
                            font-size: 2rem;
                            font-weight: bold;
                            padding-right: 4rem;
                            align-items: center;

                            &__icon {
                                @include ff-icon;
                                padding-left: 1rem;
                                padding-right: 1rem;
                                font-size: 3rem;
                            }
                        }

                        &-buttons {
                            position: relative;
                        }
                    }
                }
            }

            &__text {
                font-size: 1.7rem;
                color: rgba(black, 0.8);
                letter-spacing: 0.15rem;
                padding: 1rem 2rem 1rem 0;

                @media (min-width: $dim-breakpoint-md) {
                    text-align: right;
                    padding: 1rem 3rem 1rem 1rem;
                }
            }
        }
    }
}
