//$color-primary:  #008fa2;
$color-primary: #32a2b1;
$color-secondary: #0ee5a9;
$color-third: #86cdd6;
$color-bg-elem: #fff;
$color-bg: #fff;
$color-bg-black: #2b2e33;

$color-grey-1: rgb(117, 117, 117);
$color-grey-2: rgb(194, 194, 194);
$color-grey-3: rgb(236, 236, 236);

$color-grey-4: #f1f2f4;
$color-grey-5: rgb(94, 94, 94);
$color-grey-6: #edededed;
$color-grey-7: #b4b4b4;
