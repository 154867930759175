@import "../scss/variables/index";

.cookie-info {
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: calc(100% - 20px);
    max-width: 500px;
    z-index: 20;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    display: flex;

    &__text {
        font-size: 12px;
        line-height: 1.2em;
    }

    &__icon {
        @include ff-icon;
        display: flex;
        align-self: center;
        justify-content: center;
        flex: 0 0 40px;
        cursor: pointer;
    }
}
